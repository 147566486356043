import React, { useRef, useState } from "react";

import { Button } from "components/atoms/Button";
import { Image } from "components/atoms/Image";
import { Loading } from "components/atoms/Loading";
import { Text } from "components/atoms/Text";
import { toastSingleMode } from "components/atoms/Toastify";
import { Container } from "components/organisms/Grid";
import { Modal } from "components/organisms/Modal";
import { General } from "components/pages/general";
import { resizeImage } from "helpers/utils";
import { useAsyncAction } from "hooks/useAsyncAction";
import { apiPostPhoto } from "services/Photo";
import { useAppSelector } from "store";

import Processing, { SelfieProcessProps } from "./process";

interface Props {
	onSubmit?: (hasDraw?: boolean) => void;
}

const Photo: React.FC<Props> = ({ onSubmit }) => {
	// const imgFileOneRef = useRef<File | null>(null);
	// const imgFileTwoRef = useRef<File | null>(null);
	// const channel = useAppSelector((state) => state.stores.channel);
	const camRef = useRef<SelfieProcessProps>(null);
	const inputFileRef = useRef<HTMLInputElement>(null);
	const [isSelected, setIsSelected] = useState(false);
	const [permission, setPermission] = useState<PermissionState>("prompt");
	const [activeCam, setActiveCam] = useState(true);
	const [currentStep, setStep] = useState<number>(1);
	const [imgFile, setImgFile] = useState<File>();
	const [imgPreview, setImagePreview] = useState("");
	const [postPhotoExec, postPhotoState] = useAsyncAction(apiPostPhoto, {
		onSuccess: (data) => {
			toastSingleMode({ message: "Upload ảnh thành công!", type: "success" });
			onSubmit?.(data.data.data.hasDraw);
		},
		onFailed: () => {
			toastSingleMode({ message: "Đã xảy ra lỗi upload ảnh!", type: "error" });
		},
	});

	const handleTakePhoto = async (step: number) => {
		setStep(step);
		if (camRef.current) {
			const res = await camRef.current.takeSelfie();

			setImagePreview(res);
			fetch(res)
				.then((r) => r.blob())
				.then((a) => {
					if (a) {
						const file = new File([a], `doiquaensure2022.jpeg`, {
							type: "image/jpeg",
						});
						setImgFile(file);
					}
				});
			// imgFileTwoRef.current = dataURLtoFile(res, "customer");
		}
		setActiveCam(false);
	};

	const reTakePicture = () => {
		// imgFileOneRef.current = null;
		// imgFileTwoRef.current = null;
		setActiveCam(true);
		setStep(1);
		setIsSelected(false);
		setImgFile(undefined);
	};

	const handleTakePhotoStepTwo = () => {
		// if (imgFileOneRef.current && imgFileTwoRef.current) {
		// 	postPhotoExec({
		// 		billImage: imgFileOneRef.current,
		// 		customerImage: imgFileTwoRef.current,
		// 	});
		// } else {
		// 	setActiveCam(true);
		// 	setStep(2);
		// }
		if (imgFile) {
			postPhotoExec({
				customerImage: imgFile,
			});
		}
	};

	return (
		<General helmet={<title>Chụp hình</title>}>
			<div className="t-photo">
				{!activeCam && (
					<div style={{ maxWidth: 540 }} className="mx-auto t-photo_image">
						<Image
							src={imgPreview}
							alt="banner"
							aspectRatio="capture"
							modifiers="transparent"
						/>
					</div>
				)}
				<Container>
					{activeCam && !isSelected && (
						<div className="t-photo_capture">
							<Processing
								ref={camRef}
								handlePermission={(val) => {
									setPermission(val);
								}}
							/>
						</div>
					)}
					<div
						className="d-flex justify-content-center u-mt-10 u-pt-15 flex-column mx-auto"
						style={{ maxWidth: 400 }}
					>
						{/* {currentStep === 1 && activeCam ? (
							<Button
								disabled={permission !== "granted"}
								fullWidth
								onClick={() => handleTakePhoto(1)}
							>
								Chụp hình hóa đơn
							</Button>
						) : (
							<Button
								disabled={permission !== "granted"}
								fullWidth
								onClick={reTakePicture}
							>
								Chụp lại
							</Button>
						)} */}

						<div className="u-mt-20">
							{activeCam && currentStep === 1 ? (
								<>
									<Button
										fullWidth
										onClick={() => handleTakePhoto(1)}
										disabled={permission !== "granted"}
									>
										<span style={{ fontSize: 12 }}>
											Nhấn để chụp hình khách hàng và quà tặng
										</span>
									</Button>
									{/* {channel === "GT" && ( */}
									{/* <> */}
									<input
										type="file"
										hidden
										ref={inputFileRef}
										onChange={async (e) => {
											if (e.currentTarget.files) {
												await resizeImage(e.currentTarget.files[0], (file) => {
													setImgFile(file);
													setImagePreview(URL.createObjectURL(file));
												});
												setActiveCam(false);
												setIsSelected(true);
											}
										}}
									/>
									<div style={{ marginTop: 16 }}>
										<Button
											fullWidth
											onClick={() => inputFileRef.current?.click()}
										>
											<span style={{ fontSize: 12 }}>Chọn hình</span>
										</Button>
									</div>
									{/* </> */}
									{/* )} */}
								</>
							) : (
								<>
									<Button
										disabled={permission !== "granted"}
										fullWidth
										onClick={reTakePicture}
									>
										Chụp lại
									</Button>
									<br />
									<br />
									<Button
										disabled={
											// !imgFileOneRef.current ||
											(postPhotoState.loading || permission !== "granted") &&
											!isSelected
										}
										fullWidth
										loading={postPhotoState.loading}
										onClick={handleTakePhotoStepTwo}
									>
										<span>Hoàn tất</span>
									</Button>
									<br />
								</>
							)}
						</div>
					</div>
				</Container>
			</div>
			<Modal
				heading="Thông báo"
				isOpen={postPhotoState.loading}
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
			>
				<Text modifiers={["yankeesBlue", "center"]}>
					Vui lòng chờ
					<br />
					quá trình lưu thông tin hoàn tất.
				</Text>
				<br />
				<div style={{ position: "relative", paddingBottom: "24px" }}>
					<Loading />
				</div>
			</Modal>
		</General>
	);
};

export default Photo;
