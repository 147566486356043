/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";

import { AxiosError } from "axios";
import { OptionsType } from "react-select";
import SignatureCanvas from "react-signature-canvas";
import * as Yup from "yup";

import banner from "assets/images/common/ensureGold.png";
import { Button } from "components/atoms/Button";
import { Checkbox } from "components/atoms/Checkbox";
import { Heading } from "components/atoms/Heading";
import { Icon } from "components/atoms/Icon";
import { Image } from "components/atoms/Image";
import { Loading } from "components/atoms/Loading";
import { PhonefieldHookForm } from "components/atoms/PhoneField";
import { PulldownHookForm } from "components/atoms/Pulldown";
import { Radio } from "components/atoms/Radio";
import { Text } from "components/atoms/Text";
import { TextField, TextfieldHookForm } from "components/atoms/TextField";
import { toastSingleMode } from "components/atoms/Toastify";
import { FormField } from "components/molecules/FormField";
import { Container } from "components/organisms/Grid";
import { Modal } from "components/organisms/Modal";
import { General } from "components/pages/general";
import { Link } from "components/utils/Link";
import { confirm } from "contexts/Dialog";
import { FormContainer, useFormContainer } from "helpers/form";
import { base64EncodedImage } from "helpers/utils";
import { useAsyncAction } from "hooks/useAsyncAction";
import { useUserAuthAction } from "hooks/useUserAuthAction";
import useWindowDimensions from "hooks/useWindowDemensions";
import { SavedCustomer } from "pages";
import {
	apiFindCustomerByPhone,
	apiNemoVerify,
	apiSaveCustomer,
	apiSendOtp,
	apiSubmitSignature,
	apiVerifyOtp,
	getPreActiveCustomer,
} from "services/Customer";
import { CustomerByPhone, SavingCustomer } from "services/Customer/types";
import { useAppSelector } from "store";

const objectRadio = [
	{
		value: 1,
		label: "Người chăm sóc",
	},
	{
		value: 2,
		label: "Người sử dụng",
	},
];

export interface BackupData {
	sachetEnsure: boolean;
	sachetGlu: boolean;
	exchangeGift: boolean;
	noExchangeGift?: boolean;
	immune?: boolean;
	phone: string;
	firstname: string;
	lastname: string;
	email: string;
	gender: number;
	address: string;
	date?: object;
	month?: object;
	year?: object;
	ensureTarget?: number;
	gluTarget?: number;
	confirm: boolean;
	dupForEnsure?: boolean;
	verifiedOtp?: boolean;
	signature?: string;
}

interface Props {
	onSubmit?: () => void;
	onReload?: () => void;
	getRemainingGift: (quantity: number) => void;
	savedCustomer?: SavedCustomer;
	getSavedCustomer: (savedCustomer: SavedCustomer) => void;
	backupData?: BackupData;
	saveBackupData?: (data: BackupData) => void;
	saveSignature?: (signature: string) => void;
	handleBack?: () => void;
	channel?: "MT" | "GT" | "CV" | "CC";
}

const phoneRegExp = /^0[0-9]{9}$/g;

const dateArray = Array.from({ length: 31 }, (_, i) => ({
	label: String(i + 1),
	value: String(i + 1),
}));

const monthArray = Array.from({ length: 12 }, (_, i) => ({
	label: String(i + 1),
	value: String(i + 1),
}));

const currentYear = new Date().getFullYear();

const yearArray = Array.from({ length: 106 }, (_, i) => ({
	label: String(currentYear - 18 - i),
	value: String(currentYear - 18 - i),
}));

const gameGifts = [
	{
		value: "gamesuaensure",
		label: "Chai sữa Ensure",
	},
	{
		value: "gamebinhgiunhietorltt",
		label: "Bình giữ nhiệt/Ly thủy tinh",
	},
	{
		value: "gamedaydaikhanglucorltt",
		label: "Dây đai kháng lực/Ly thủy tinh",
	},
	{
		value: "gamebinhnhuall",
		label: "Bình nhựa L&L",
	},
];

const IndexPage: React.FC<Props> = ({
	onSubmit,
	getRemainingGift,
	onReload,
	savedCustomer,
	getSavedCustomer,
	backupData,
	saveBackupData,
	saveSignature,
	handleBack,
	channel,
}) => {
	const { width } = useWindowDimensions();
	const { register, methodsRef: formMethodsRef } = useFormContainer();
	const { handleLogout } = useUserAuthAction();
	const verifiedOtpRef = useRef(false);
	const timerRef = useRef<HTMLSpanElement | null>(null);

	const [giftState, setGiftState] = useState(false);
	const [noGiftState, setNoGiftState] = useState(false);
	const [gameState, setGameState] = useState(false);
	// const [immuneState, setImmuneState] = useState(false);
	const [gender, setGender] = useState("male");
	const [sachetEnsureState, setSachetEnsureState] = useState(false);
	const [sachetGluState, setSachetGluState] = useState(false);
	const [sampleState, setSampleState] = useState(!!savedCustomer?.sample);
	const [policyCheck, setPolicyCheck] = useState(false);
	const [policyPopup, setPolicyPopup] = useState(false);
	const [canVerifyOtp, setCanVerifyOtp] = useState(false);
	const [otpDeactive, setOtpDeactive] = useState(false);
	const [countDown, setCountDown] = useState(false);
	const [formDataActive, setFormDataActive] = useState(-1); // 0: Ensure; 1: Glucerna
	const storeInfo = useAppSelector((state) => state.stores);
	const scanner = useAppSelector((state) => state.auth.scanner);
	const [ensureState, setEnsureState] = useState(savedCustomer?.ensureTarget);
	const [glucenaState, setGlucenaState] = useState(
		savedCustomer?.glucernaTarget
	);
	const [customerType, setCustomerType] = useState<boolean | undefined>(
		undefined
	);
	const [noShareInfo, setNoShareInfo] = useState(false);
	const [signatureCanvas, setSignatureCanvas] = useState<SignatureCanvas>();
	const [signImg, setSignImg] = useState<string | null>(null);
	const [isSigned, setIsSigned] = useState(false);
	const [loading, setLoading] = useState(false);

	const activeButtonRef = useRef(false);
	/**
	 * Ignore Form: When user doesn't choose 'sachet Gift' checkbox
	 * using form data from previous form (api customer/phone glucerna or ensure) & submit save customer
	 */
	const [ignoreForm, setIgnoreForm] = useState<SavingCustomer>();

	// Memos
	const requiredOtp = useMemo(() => {
		// - channel === MT
		// - channel === GT && (sachetEnsureState || noGiftState)
		// - channel === CV && (sachetEnsureState || noGiftState)
		return (
			channel === "MT" ||
			(channel === "GT" && (sachetEnsureState || noGiftState)) ||
			(channel === "CC" && (sachetEnsureState || noGiftState) && !giftState) ||
			(channel === "CV" && (sachetEnsureState || noGiftState))
		);
	}, [channel, sachetEnsureState, noGiftState, giftState]);

	const handleClearSignature = () => {
		signatureCanvas?.clear();
		setIsSigned(false);
	};

	const handleEndSignature = () => {
		setIsSigned(true);
	};

	const [sendOtpExec, sendOtpState] = useAsyncAction(apiSendOtp, {
		onSuccess: () => {
			setCountDown(true);
		},
		onFailed: () => {
			toastSingleMode({ message: "Lỗi gửi OTP!", type: "error" });
		},
	});

	const [verifyOtpExec, verifyOtpState] = useAsyncAction(apiVerifyOtp, {
		onSuccess: () => {
			toastSingleMode({ message: "Xác nhận OTP thành công!", type: "success" });
			verifiedOtpRef.current = true;
		},
		onFailed: () => {
			toastSingleMode({ message: "Lỗi xác nhận OTP!", type: "error" });
			verifiedOtpRef.current = false;
		},
	});

	const [
		getPreActiveExec,
		getPreActiveState,
		resetPreActiveState,
	] = useAsyncAction(getPreActiveCustomer, {
		// onFailed: () => {
		// 	formMethodsRef.current.reset();
		// },
	});

	const [saveSignatureExec, saveSignatureState] = useAsyncAction(
		apiSubmitSignature,
		{
			onSuccess: () => {
				onSubmit?.();
			},
			onFailed: () => {
				toastSingleMode({
					message: "Lỗi lưu chữ ký!",
					type: "error",
				});
			},
		}
	);

	const [verifyNemoCustomerExec, verifyNemoCustomerState] = useAsyncAction(
		apiNemoVerify,
		{
			onSuccess: ({ dupForEnsure }) => {
				setCustomerType(dupForEnsure);
			},
		}
	);

	const [findCustomerExec, findCustomerState] = useAsyncAction(
		apiFindCustomerByPhone,
		{
			onSuccess: ({
				data: {
					data: {
						sachet,
						sachetGlucerna,
						ensureTarget,
						glucernaTarget,
						gender: genderapi,
						isVerifyOtp,
						signature,
					},
				},
			}) => {
				resetPreActiveState();
				setSignImg(null);
				handleClearSignature();
				// if (
				// 	!(channel === "CV" && giftState) &&
				// 	!(!sachetEnsureState && !gameState && !giftState && !noGiftState)
				// ) {
				// 	toastSingleMode({
				// 		message:
				// 			!isVerifyOtp && channel === "GT" && !giftState
				// 				? "Đã gửi OTP!"
				// 				: "Số điện thoại đã được xác nhận OTP!",
				// 		type: "success",
				// 	});
				// }
				if (signature) {
					setSignImg(signature);
					setIsSigned(true);
				}
				verifiedOtpRef.current = true;
				if (sachet) setSachetEnsureState(sachet);
				// if (sachetGlucerna) setSachetGluState(sachetGlucerna);
				if (ensureTarget) setEnsureState(ensureTarget);
				if (glucernaTarget) setGlucenaState(glucernaTarget);
				if (genderapi) setGender(genderapi === 1 ? "male" : "female");
				if (!isVerifyOtp && requiredOtp) {
					sendOtpExec({
						data: {
							phone: formMethodsRef.current.getValues("phone"),
						},
						is_glucerna: 0,
					});
					verifiedOtpRef.current = false;
				}
			},
			onFailed: (error: AxiosError) => {
				handleClearSignature();
				setSignImg(null);
				if (error?.response?.status === 404) {
					// toastSingleMode({
					// 	message: "Khách hàng mới. Vui lòng nhập thông tin",
					// 	type: "error",
					// });
					getPreActiveExec(formMethodsRef.current.getValues("phone"));
					if (requiredOtp) {
						sendOtpExec({
							data: {
								phone: formMethodsRef.current.getValues("phone"),
							},
							is_glucerna: 0,
						});
					}
					verifiedOtpRef.current = false;
				}
			},
		}
	);

	const [saveCustomerExec, saveCustomerState] = useAsyncAction(
		(params: SavingCustomer, canGoNext: boolean, isGlucerna?: number) => {
			return apiSaveCustomer(params, isGlucerna || 0)
				.then(async ({ data: { data } }) => {
					if (
						canGoNext &&
						(giftState ||
							(noGiftState && (customerByPhone?.sachet || sachetEnsureState)))
					) {
						getRemainingGift(Number(data.todayPrizesLeft));
						getSavedCustomer({
							...data,
							sachet: undefined,
							sachetGlucerna: undefined,
							isExchangedGift: giftState,
							ensureObj: ensureState,
							exchange:
								(channel === "GT" || channel === "CV" || channel === "CC") &&
								giftState,
							// exchange_immune: immuneState,
							glucernaTarget: undefined,
						});
						if (!signImg && isSigned && !noGiftState) {
							await apiSubmitSignature(
								base64EncodedImage(
									signatureCanvas?.getTrimmedCanvas().toDataURL("image/png") ||
										"",
									"image/jpeg"
								),
								sachetEnsureState && !giftState && !noGiftState
							).then((signature) => {
								if (saveSignature) saveSignature(signature.path);
							});
							onSubmit?.();
							setLoading(false);
							return;
						}

						onSubmit?.();
						setLoading(false);
						return;
					}
					if (!signImg && isSigned && !noGiftState) {
						await apiSubmitSignature(
							base64EncodedImage(
								signatureCanvas?.getTrimmedCanvas().toDataURL("image/png") ||
									"",
								"image/jpeg"
							),
							sachetEnsureState && !giftState && !noGiftState
						).then((signature) => {
							if (saveSignature) saveSignature(signature.path);
						});
					}
					setLoading(false);

					confirm({
						title: "Đã lưu thông tin khách hàng",
						okLabel: "OK",
					}).then(() => {
						if (onReload) onReload();
					});

					formMethodsRef.current.reset({});
					handleClearSignature();
				})
				.catch((error) => {
					setLoading(false);

					const { errors } = error.response.data;
					if (Array.isArray(errors)) {
						errors.forEach((val) => {
							if (val.code === "4229") {
								toastSingleMode({
									message: "Đã nhận Sachet Ensure",
									type: "error",
								});
							}
							if (val.code === "4223") {
								toastSingleMode({
									message: "Hết quà",
									type: "error",
								});
							}
							if (val.code === "4231") {
								toastSingleMode({
									message:
										"Bạn đã nhận quà của hoạt động này. Vui lòng tham gia hoạt động khác",
									type: "error",
								});
							}
							if (val.code === "4232") {
								toastSingleMode({
									message:
										"Bạn đã nhận quà của hoạt động này. Vui lòng tham gia hoạt động khác",
									type: "error",
								});
							}
						});
					} else {
						toastSingleMode({
							message: "Đã có lỗi xảy ra. Vui lòng thử lại sau",
							type: "error",
						});
					}
				});
		}
	);

	const customerByPhone = useMemo(
		() => findCustomerState.data?.data?.data || savedCustomer,
		[findCustomerState, savedCustomer]
	);
	const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	const validationSchema = Yup.object({
		phone: noShareInfo
			? Yup.string().notRequired()
			: Yup.string()
					.required("Vui lòng nhập số điện thoại")
					.matches(phoneRegExp, "Vui lòng nhập số điện thoại hợp lệ"),
		otp:
			verifiedOtpRef.current || otpDeactive || noShareInfo || !requiredOtp
				? Yup.string().notRequired()
				: Yup.string()
						.length(6, "OTP phải bao gồm 6 con số")
						.required("Vui lòng nhập OTP"),
		address: Yup.string().required("Vui lòng nhập địa chỉ"),
		firstname: Yup.string().required("Vui lòng nhập họ"),
		lastname: Yup.string().required("Vui lòng nhập tên"),
		email: Yup.string().email("Email không hợp lệ"),
		date: Yup.object().required("Vui lòng nhập ngày"),
		month: Yup.object().required("Vui lòng nhập tháng"),
		year: Yup.object().required("Vui lòng nhập năm"),
	});

	/**
	 * Autofill with previous data in Glucerna form
	 */
	useEffect(() => {
		if (customerByPhone) {
			formMethodsRef.current.setValue("firstname", customerByPhone.firstname);
			formMethodsRef.current.setValue("lastname", customerByPhone.lastname);
			formMethodsRef.current.setValue("phone", customerByPhone.phone);
			formMethodsRef.current.setValue("address", customerByPhone.address);
			formMethodsRef.current.setValue("birthday", customerByPhone.birthday);
		} else {
			formMethodsRef.current.setValue("firstname", "");
			formMethodsRef.current.setValue("lastname", "");
			formMethodsRef.current.setValue("address", "");
			formMethodsRef.current.setValue("birthday", "");
		}
	}, [customerByPhone, formMethodsRef]);

	useEffect(() => {
		if (getPreActiveState.data) {
			formMethodsRef.current.setValue("name", getPreActiveState.data.name);
			formMethodsRef.current.setValue("phone", getPreActiveState.data.phone);
			formMethodsRef.current.setValue(
				"address",
				getPreActiveState.data.address
			);
			formMethodsRef.current.setValue(
				"birthday",
				getPreActiveState.data.birthday
			);
		} else {
			formMethodsRef.current.setValue("name", "");
			formMethodsRef.current.setValue("address", "");
			formMethodsRef.current.setValue("birthday", "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getPreActiveState.data]);

	useEffect(() => {
		if (backupData) {
			formMethodsRef.current.reset(backupData);
			setSachetEnsureState(backupData.sachetEnsure);
			// setSachetGluState(backupData.sachetGlu);
			setGiftState(backupData.exchangeGift);
			setNoGiftState(backupData.noExchangeGift || false);
			// setImmuneState(backupData.immune);
			setPolicyCheck(backupData.confirm);
			setGender(backupData.gender === 1 ? "male" : "female");
			setCustomerType(backupData.dupForEnsure);
			if (backupData.signature) {
				setIsSigned(true);
				setSignImg(backupData.signature || null);
			}
			verifiedOtpRef.current = backupData.verifiedOtp || false;
		}
	}, [backupData, formMethodsRef]);

	const handleCheckPhone = () => {
		activeButtonRef.current = true;
		const { getValues, setError, clearErrors } = formMethodsRef.current;
		const phone = getValues("phone");

		clearErrors();

		validationSchema.fields?.phone
			.validate(phone)
			.then(async () => {
				await verifyNemoCustomerExec(phone);
				await findCustomerExec({
					phone,
					is_glucerna: 0,
					// is_exchange_gift: giftState ? 1 : 0,
				});
			})
			.catch((error) => {
				setError("phone", error);
			});
	};

	const handleChangeOtp = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;

		if (value && !canVerifyOtp) {
			setCanVerifyOtp(true);
			setCountDown(false);

			return;
		}

		if (!value && canVerifyOtp) {
			setCanVerifyOtp(false);
			setCountDown(false);
		}
	};

	const handleVerifyOtp = () => {
		const { getValues, setError } = formMethodsRef.current;
		const { otp, phone } = getValues();

		validationSchema.fields?.otp
			.validate(otp)
			.then(() => {
				verifyOtpExec({
					params: {
						otp,
						phone,
					},
					is_glucerna: 0,
				});
			})
			.catch((error) => {
				setError("otp", error);
			});
	};

	const handleChangeGift = (event: ChangeEvent<HTMLInputElement>) => {
		setGiftState(!!Number(event?.target.value));
		if (event?.target.value === "1") {
			setNoGiftState(false);
			setGameState(false);
		}
	};

	const handleChangeNoGift = (event: ChangeEvent<HTMLInputElement>) => {
		setNoGiftState(!!Number(event?.target.value));
		if (event?.target.value === "1") {
			setSachetEnsureState(true);
			setGiftState(false);
			setGameState(false);
		}
	};

	// const handleChangeImmune = (event: ChangeEvent<HTMLInputElement>) => {
	// 	setImmuneState(!!Number(event?.target.value));
	// };

	const handleChangeSample = (event: ChangeEvent<HTMLInputElement>) => {
		setSampleState(!!Number(event?.target.value));
	};

	const handleChangeSachetEnsure = (event: ChangeEvent<HTMLInputElement>) => {
		setSachetEnsureState(!!Number(event?.target.value));
		setGameState(false);
	};

	const hanldeSelectGame = (event: ChangeEvent<HTMLInputElement>) => {
		setGameState(!!Number(event?.target.value));
		if (event?.target.value === "1") {
			setSachetEnsureState(false);
			setGiftState(false);
			setNoGiftState(false);
		}
	};

	const handleSaveCustomer = (canGoNext: boolean) => (
		formData: SavingCustomer
	) => {
		const gameGift = formData.gift ? { [formData.gift.value]: 1 } : undefined;
		setLoading(true);
		if (!sachetEnsureState && ignoreForm) {
			saveCustomerExec(
				{
					...formData,
					sachet: sachetEnsureState && !noShareInfo,
					sachet_glucerna: sachetGluState,
					ensure_target: ensureState,
					glucerna_target: glucenaState,
					firstname: ignoreForm.firstname,
					lastname: ignoreForm.lastname,
					gender: ignoreForm.gender || gender === "male" ? 1 : 2,
					email: ignoreForm.email,
					phone: noShareInfo ? "0000000000" : ignoreForm.phone,
					exchange: giftState,
					no_exchange: noGiftState,
					// exchange_immune: immuneState,
					address: ignoreForm.address,
					birthday: ignoreForm.birthday,
					dup_for_ensure: customerType,
					exchange_sachet: sachetEnsureState && giftState,
					policy_and_condition: true,
					no_share_info: noShareInfo,
					gift: gameGift,
					save_nemo:
						!!customerByPhone?.signature &&
						(sachetEnsureState || gameState) &&
						!giftState &&
						!noGiftState,
					is_internal:
						!sachetEnsureState && !gameState && !giftState && !noGiftState,
				},
				canGoNext
			);
		} else {
			saveCustomerExec(
				{
					...formData,
					phone: noShareInfo ? "0000000000" : formData.phone,
					sachet: sachetEnsureState && !noShareInfo,
					sachet_glucerna: sachetGluState,
					// exchange_immune: immuneState,
					no_exchange: noGiftState,
					exchange: giftState,
					gender: gender === "male" ? 1 : 2,
					ensure_target: ensureState,
					glucerna_target: glucenaState,
					birthday: `${Number(formData.year?.value)}-${
						Number(formData.month?.value) < 10
							? `0${Number(formData.month?.value)}`
							: Number(formData.month?.value)
					}-${
						Number(formData.date?.value) < 10
							? `0${Number(formData.date?.value)}`
							: Number(formData.date?.value)
					}`,
					dup_for_ensure: customerType,
					exchange_sachet: sachetEnsureState && giftState,
					policy_and_condition: true,
					no_share_info: noShareInfo,
					gift: gameGift,
					save_nemo:
						!!customerByPhone?.signature &&
						(sachetEnsureState || gameState) &&
						!giftState &&
						!noGiftState,
					is_internal:
						!sachetEnsureState && !gameState && !giftState && !noGiftState,
				},
				canGoNext
			);
			if (saveBackupData) {
				saveBackupData({
					sachetEnsure: sachetEnsureState && !noShareInfo,
					sachetGlu: sachetGluState,
					exchangeGift: giftState,
					noExchangeGift:
						noGiftState && (customerByPhone?.sachet || sachetEnsureState),
					// immune: immuneState,
					phone: formData.phone,
					firstname: formData.firstname,
					lastname: formData.lastname,
					email: formData.email || "",
					gender: gender === "male" ? 1 : 2,
					address: formData.address,
					date: formData.date,
					month: formData.month,
					year: formData.year,
					ensureTarget: ensureState,
					gluTarget: glucenaState,
					confirm: true,
					dupForEnsure: customerType,
					verifiedOtp: verifiedOtpRef.current,
					signature: signImg || undefined,
				});
			}
		}
	};

	const handleContinue = () => {
		const { getValues } = formMethodsRef.current;

		if (
			!noShareInfo &&
			!verifiedOtpRef.current &&
			!otpDeactive &&
			!(
				(channel === "GT" || channel === "CV" || channel === "CC") &&
				giftState
			) &&
			!(!sachetEnsureState && !gameState && !giftState && !noGiftState) &&
			!(channel === "CV" && gameState)
		) {
			toastSingleMode({
				type: "error",
				description: "Vui lòng xác nhận OTP!",
			});
			return;
		}

		if (channel === "CV" && gameState && getValues().gift === undefined) {
			toastSingleMode({
				type: "error",
				description: "Vui lòng chọn quà",
			});
			return;
		}

		if (!ensureState && sachetEnsureState) {
			toastSingleMode({
				type: "error",
				description: "Vui lòng chọn đối tượng nhãn hàng Ensure",
			});
			return;
		}
		if (getValues().email && !emailRegExp.test(getValues().email)) {
			toastSingleMode({
				type: "error",
				description: "Email không được chứa dấu tiếng Việt",
			});
			return;
		}

		// if (!glucenaState && sachetGluState) {
		// 	toastSingleMode({
		// 		type: "error",
		// 		description: "Vui lòng chọn đối tượng nhãn hàng Glucerna",
		// 	});
		// 	return;
		// }

		if (giftState && !ensureState) {
			toastSingleMode({
				type: "error",
				description: "Vui lòng chọn đối tượng nhãn hàng Ensure",
			});
			return;
		}

		if (!isSigned && !noGiftState) {
			toastSingleMode({
				type: "error",
				description: "Vui lòng ký tên",
			});
			return;
		}

		if (!policyCheck) {
			toastSingleMode({
				type: "error",
				description: "Vui lòng chọn đồng ý điều khoản",
			});
			return;
		}
		const { handleSubmit } = formMethodsRef.current;

		if (
			giftState ||
			(noGiftState && (customerByPhone?.sachet || sachetEnsureState))
		) {
			// NOTE: Save to make later api workable
			handleSubmit(
				handleSaveCustomer(
					!(!sachetEnsureState && !gameState && !giftState && !noGiftState)
				)
			)();
			return;
		}

		handleSubmit(
			handleSaveCustomer(
				!(!sachetEnsureState && !gameState && !giftState && !noGiftState)
			)
		)();
		// if (sachetState) {
		// 	handleSubmit(handleSaveCustomer(false))();
		// }
		// // if (sampleState) {
		// // 	handleSubmit(handleSaveCustomer(false))();
		// // }

		// if (ensureState) {
		// 	handleSubmit(handleSaveCustomer(false))();
		// }
	};

	/**
	 * Deactive OTP validation (MT channel only)
	 */
	useEffect(() => {
		if (channel !== "MT") return;
		setOtpDeactive(!sachetEnsureState);
	}, [sachetEnsureState, channel]);

	/**
	 * check phone number is verified ?
	 */
	useEffect(() => {
		(async () => {
			const phone = formMethodsRef.current.getValues("phone");
			if (phone) {
				try {
					// ensure verified !
					const resEnsure = await apiFindCustomerByPhone({
						phone,
						is_glucerna: 0,
					});
					// console.log("ensure: ", resEnsure.data.data);
					setFormDataActive(0);
					setIgnoreForm(resEnsure.data.data);
				} catch (error) {
					// console.log("glu: ", resGlucerna.data.data);
					const resGlucerna = await apiFindCustomerByPhone({
						phone,
						is_glucerna: 0,
					});
					// setFormDataActive(1);
					setIgnoreForm(resGlucerna.data.data);
				}
			}
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!activeButtonRef.current) return undefined;
		if (!countDown) return undefined;

		const timerTarget = timerRef.current;

		if (!timerTarget) return undefined;

		let timeLeft = 30;

		const countDownTimer = setInterval(() => {
			if (timeLeft <= 0) {
				clearInterval(countDownTimer);
				setCountDown(false);
			} else {
				timerTarget.innerHTML = `${
					timeLeft < 10 ? `0${timeLeft}` : timeLeft
				} s`;
			}

			timeLeft -= 1;
		}, 1000);

		return () => {
			clearInterval(countDownTimer);
		};
	}, [countDown, findCustomerState.loading, sendOtpState.loading]);

	return (
		<General helmet={<title>Thông tin khách hàng</title>}>
			<div className="customerInfo_logout" onClick={handleLogout}>
				<Icon iconName="logout" />
			</div>
			<Container>
				<div className="d-flex justify-content-center mb-5 pb-2 pt-4">
					<Image src={banner} alt="banner" />
				</div>

				{scanner && <div>Scanner: {scanner}</div>}

				<FormContainer
					validationSchema={validationSchema}
					onSubmit={handleSaveCustomer}
					register={register}
				>
					<div style={{ fontSize: 20 }}>
						{storeInfo.channel} - {storeInfo.code} - {storeInfo.name}{" "}
					</div>
					<section className="u-pt-20">
						<Heading>HOẠT ĐỘNG THAM GIA</Heading>

						<FormField label="Tặng sachet Ensure Gold" modifiers="customerInfo">
							<div
								className="d-flex align-items-center justify-content-between u-mt-10 ml-auto"
								style={{ maxWidth: 180 }}
							>
								<Radio
									value="1"
									name="sachetEnsure"
									checked={sachetEnsureState}
									onChange={handleChangeSachetEnsure}
									disabled={noGiftState}
								>
									Có
								</Radio>
								<Radio
									value="0"
									name="sachetEnsure"
									checked={!sachetEnsureState}
									onChange={handleChangeSachetEnsure}
									disabled={noGiftState}
								>
									Không
								</Radio>
							</div>
						</FormField>
						{channel === "CV" && (
							<FormField label="Game tương tác" modifiers="customerInfo">
								<div
									className="d-flex align-items-center justify-content-between u-mt-10 ml-auto"
									style={{ maxWidth: 180 }}
								>
									<Radio
										value="1"
										name="gameState"
										checked={gameState}
										onChange={hanldeSelectGame}
									>
										Có
									</Radio>
									<Radio
										value="0"
										name="gameState"
										checked={!gameState}
										onChange={hanldeSelectGame}
									>
										Không
									</Radio>
								</div>
							</FormField>
						)}

						{/* {customerByPhone?.sachetGlucerna ? (
							<div className="u-mt-15">
								<Text>Khách hàng đã nhận sachet Glucerna </Text>
							</div>
						) : (
							<FormField label="Tặng sachet Glucerna" modifiers="customerInfo">
								<div
									className="d-flex align-items-center justify-content-between ml-auto"
									style={{ maxWidth: 180 }}
								>
									<Radio
										value="1"
										name="sachetGlucerna"
										onChange={handleChangeSachetGlu}
										checked={sachetGluState}
									>
										Có
									</Radio>
									<Radio
										value="0"
										name="sachetGlucerna"
										onChange={handleChangeSachetGlu}
										checked={!sachetGluState}
									>
										Không
									</Radio>
								</div>
							</FormField>
						)} */}

						<FormField label="Mua hàng đổi quà" modifiers="customerInfo">
							<div
								className="d-flex align-items-center justify-content-between ml-auto"
								style={{ maxWidth: 180 }}
							>
								<Radio
									value="1"
									name="gift"
									onChange={handleChangeGift}
									checked={giftState}
								>
									Có
								</Radio>
								<Radio
									value="0"
									name="gift"
									onChange={handleChangeGift}
									checked={!giftState}
								>
									Không
								</Radio>
							</div>
						</FormField>
						<FormField label="Mua hàng không đổi quà" modifiers="customerInfo">
							<div
								className="d-flex align-items-center justify-content-between ml-auto"
								style={{ maxWidth: 180 }}
							>
								<Radio
									value="1"
									name="nogift"
									onChange={handleChangeNoGift}
									checked={noGiftState}
								>
									Có
								</Radio>
								<Radio
									value="0"
									name="nogift"
									onChange={handleChangeNoGift}
									checked={!noGiftState}
								>
									Không
								</Radio>
							</div>
						</FormField>
						{/* {channel === "GT" && (
							<FormField
								label="Mua sản phẩm Immune gift pack"
								modifiers="customerInfo"
							>
								<div
									className="d-flex align-items-center justify-content-between ml-auto"
									style={{ maxWidth: 180 }}
								>
									<Radio
										value="1"
										name="immune"
										onChange={handleChangeImmune}
										checked={immuneState}
									>
										Có
									</Radio>
									<Radio
										value="0"
										name="immune"
										onChange={handleChangeImmune}
										checked={!immuneState}
									>
										Không
									</Radio>
								</div>
							</FormField>
						)} */}
					</section>
					<section className="u-mt-20">
						<div className="u-pb-5">
							<Heading>THÔNG TIN KHÁCH HÀNG</Heading>
						</div>
						{noGiftState && (
							<FormField label=" " modifiers="customerInfo">
								<Checkbox
									name="no_share_info"
									checked={noShareInfo}
									onChange={() => {
										setNoShareInfo(!noShareInfo);
									}}
								>
									Khách mua không cho thông tin
								</Checkbox>
							</FormField>
						)}
						{!noShareInfo && (
							<FormField label="Số điện thoại" modifiers="customerInfo">
								<div className="d-flex">
									<div className="flex-grow-1">
										<PhonefieldHookForm
											name="phone"
											defaultValue={customerByPhone?.phone}
										/>
									</div>
									{!canVerifyOtp && (
										<div className="u-ml-5 u-mt-sm-0 d-flex minw-55 customerInfo_btnOtp">
											<Button
												modifiers={["otp", "smallSize"]}
												disabled={
													activeButtonRef.current &&
													(findCustomerState.loading ||
														sendOtpState.loading ||
														verifyOtpState.loading ||
														verifyNemoCustomerState.loading ||
														countDown)
												}
												loading={
													activeButtonRef.current &&
													(findCustomerState.loading ||
														sendOtpState.loading ||
														verifyNemoCustomerState.loading ||
														verifyOtpState.loading)
												}
												onClick={handleCheckPhone}
											>
												{activeButtonRef.current && sendOtpState.data ? (
													<span
														className="d-block"
														style={{
															fontSize: countDown ? 9 : 10,
															lineHeight: 1.2,
														}}
													>
														Gửi lại OTP sau{" "}
														{countDown && <span ref={timerRef} />}
													</span>
												) : (
													<span className="d-block">
														{requiredOtp ? "Gửi OTP" : "Kiểm tra"}
													</span>
												)}
											</Button>
										</div>
									)}
								</div>
							</FormField>
						)}

						{customerType !== undefined && (
							<FormField label="Đối Tượng Khách Hàng" modifiers="customerInfo">
								<Text modifiers={["18x26", "italic"]}>
									{customerType ? "Khách hàng hiện tại" : "Khách hàng mới"}
								</Text>
							</FormField>
						)}
						{verifiedOtpRef.current || noShareInfo || !requiredOtp ? null : (
							<FormField label="Mã OTP" modifiers="customerInfo">
								<div className="d-flex">
									<div className="flex-grow-1">
										<PhonefieldHookForm name="otp" onChange={handleChangeOtp} />
									</div>
									{canVerifyOtp && (
										<div className="u-ml-10 u-mt-sm-0 d-flex minw-55">
											<Button
												modifiers={["otp", "smallSize"]}
												disabled={
													findCustomerState.loading ||
													sendOtpState.loading ||
													verifyOtpState.loading ||
													countDown
												}
												loading={
													findCustomerState.loading ||
													sendOtpState.loading ||
													verifyOtpState.loading
												}
												onClick={handleVerifyOtp}
											>
												<span>Xác nhận OTP</span>
											</Button>
										</div>
									)}
								</div>
							</FormField>
						)}

						<FormField label="Họ" modifiers="customerInfo">
							<TextfieldHookForm
								name="firstname"
								defaultValue={customerByPhone?.firstname}
							/>
						</FormField>

						<FormField label="Tên" modifiers="customerInfo">
							<TextfieldHookForm
								name="lastname"
								defaultValue={customerByPhone?.lastname}
							/>
						</FormField>

						<FormField label="Email" modifiers="customerInfo">
							<TextfieldHookForm
								name="email"
								defaultValue={customerByPhone?.email}
							/>
						</FormField>

						<FormField label="Giới tính" modifiers="customerInfo">
							<div
								className="d-flex align-items-center justify-content-between ml-auto"
								style={{ maxWidth: 180 }}
							>
								<Radio
									value="male"
									name="gender"
									onChange={(e) => setGender(e.currentTarget.value)}
									checked={gender === "male"}
								>
									Nam
								</Radio>
								<Radio
									value="female"
									name="gender"
									onChange={(e) => setGender(e.currentTarget.value)}
									checked={gender === "female"}
								>
									Nữ
								</Radio>
							</div>
						</FormField>

						<FormField label="Địa chỉ" modifiers="customerInfo">
							<TextfieldHookForm
								name="address"
								defaultValue={customerByPhone?.address}
							/>
						</FormField>

						<FormField label="Ngày tháng năm sinh" modifiers="customerInfo">
							<div className="d-flex">
								<PulldownHookForm
									name="date"
									placeholder="dd"
									options={dateArray}
									value={
										customerByPhone && customerByPhone.birthday
											? {
													label: String(
														new Date(customerByPhone.birthday).getDate()
													),
													value: String(
														new Date(customerByPhone.birthday).getDate()
													),
											  }
											: undefined
									}
								/>
								<PulldownHookForm
									name="month"
									placeholder="mm"
									options={monthArray}
									value={
										customerByPhone && customerByPhone.birthday
											? {
													label: String(
														new Date(customerByPhone.birthday).getMonth() + 1
													),
													value: String(
														new Date(customerByPhone.birthday).getMonth() + 1
													),
											  }
											: undefined
									}
								/>
								<PulldownHookForm
									name="year"
									placeholder="yyyy"
									options={yearArray}
									value={
										customerByPhone && customerByPhone.birthday
											? {
													label: String(
														new Date(customerByPhone.birthday).getFullYear()
													),
													value: String(
														new Date(customerByPhone.birthday).getFullYear()
													),
											  }
											: undefined
									}
								/>
							</div>
						</FormField>
						{channel === "CV" && gameState && (
							<div className="customerInfo_game">
								<FormField label="Quà game tương tác" modifiers="customerInfo">
									<PulldownHookForm
										name="gift"
										placeholder="--"
										options={gameGifts}
									/>
								</FormField>
							</div>
						)}
					</section>

					<section className="u-pt-45 u-pb-30">
						<Text modifiers={["18x26"]}>
							VUI LÒNG TRẢ LỜI CÁC CÂU HỎI BÊN DƯỚI:
						</Text>
						<Text>
							Đối với nhãn hàng Ensure, bạn thuộc đối tượng nào dưới đây?
						</Text>
						<FormField modifiers={["customerInfo", "block", "noMargin"]}>
							<div
								className="d-flex align-items-center justify-content-between u-mt-10"
								style={{ maxWidth: 375 }}
							>
								{objectRadio.map((val, idx) => (
									<Checkbox
										value={val.value}
										key={idx.toString()}
										checked={ensureState === val.value}
										onChange={() =>
											ensureState === val.value
												? setEnsureState(undefined)
												: setEnsureState(val.value)
										}
									>
										{val.label}
									</Checkbox>
								))}
							</div>
						</FormField>
					</section>
					<section className="u-pt-30">
						<Checkbox
							value="policy"
							name="policy"
							checked={policyCheck}
							onChange={() => {
								setPolicyCheck(!policyCheck);
							}}
						>
							Tôi đã đọc và đồng ý với các{" "}
							<p
								onClick={() => setPolicyPopup(true)}
								style={{
									textDecoration: "underline",
									display: "inline",
									cursor: "pointer",
								}}
							>
								điều khoản và điều kiện
							</p>
						</Checkbox>
					</section>

					<div style={{ marginTop: 30 }}>
						<div className="customerInfo_signature">
							{signImg ? (
								<img src={signImg} alt="signature" />
							) : (
								<>
									<SignatureCanvas
										canvasProps={{
											className: "sigCanvas",
											width: `${width - 30}px`,
											height: "400px",
										}}
										ref={(data: any) => setSignatureCanvas(data)}
										onEnd={handleEndSignature}
									/>
									<div
										className="customerInfo_signature_clear"
										onClick={handleClearSignature}
									>
										<Icon iconName="clear" />
									</div>
								</>
							)}
						</div>
					</div>

					<div style={{ textAlign: "center", marginTop: 30 }}>
						{/* <div style={{ marginRight: 10, display: "inline-block" }}>
							<Button onClick={handleBack}>Quay lại</Button>
						</div> */}
						<Button
							onClick={handleContinue}
							loading={saveCustomerState.loading || saveSignatureState.loading}
							disabled={saveCustomerState.loading || saveSignatureState.loading}
						>
							{giftState ||
							(noGiftState && (customerByPhone?.sachet || sachetEnsureState))
								? "Tiếp tục"
								: "Hoàn tất"}
						</Button>
					</div>
				</FormContainer>
			</Container>
			<Modal
				heading="Điều khoản và điều kiện"
				isOpen={policyPopup}
				closable
				shouldCloseOnEsc
				shouldCloseOnOverlayClick
				onCloseModal={() => setPolicyPopup(false)}
			>
				<Text modifiers={["yankeesBlue", "18x26"]}>
					1. Tôi đồng ý rằng thông tin mà tôi cung cấp sẽ được Abbott / 3A thu
					thập và sử dụng để cung cấp cho tôi thông tin về chăm sóc sức khoẻ,
					dinh dưỡng, quảng cáo, khuyến mãi sản phẩm. Tôi đồng ý rằng Abbott /
					3A có thể chia sẻ thông tin cá nhân này với các chi nhánh và các bên
					thứ ba được uỷ quyền để hỗ trợ thu thập, xử lý và sử dụng các thông
					tin mà tôi cung cấp.
				</Text>
				<br />
				<Text modifiers={["yankeesBlue", "18x26"]}>
					2. Tôi đồng ý rằng Abbott / 3A được gửi nhiều hơn số lượng tin nhắn,
					thư điện tử, cuộc gọi theo quy định pháp luật, và được liên lạc quảng
					cáo trước 7 giờ và sau 22 giờ ( đối với tin nhắn) và trước 8 giờ và
					sau 17 giờ ( đối với gọi điện ).
				</Text>
			</Modal>
			<Modal
				heading="Thông báo"
				isOpen={loading}
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
			>
				<Text modifiers={["yankeesBlue", "center"]}>
					Vui lòng chờ
					<br />
					quá trình lưu thông tin hoàn tất.
				</Text>
				<br />
				<div style={{ position: "relative", paddingBottom: "24px" }}>
					<Loading />
				</div>
			</Modal>
		</General>
	);
};

export default IndexPage;
